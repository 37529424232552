<template>
	<div class="d-flex justify-center align-center" style="height: 100%;">
		<v-card class="card pb-5">
		<v-card-title class="justify-center">注册</v-card-title>
			<v-card-text>
				<v-form ref="form" v-model="valid" lazy-validation>
	
					<v-text-field v-model="email" label="邮箱" required :rules="[rules.email]"></v-text-field>

					<div class="d-flex">
						<v-text-field v-model="code" label="验证码" required :rules="[rules.required]"></v-text-field>
						
						<v-btn v-show="show_sned_code" @click="sendCode" :loading="send_code_loading" class="code text-none">获取验证码</v-btn>
						<v-btn v-show="!show_sned_code" disabled class="code">{{auth_time}}s</v-btn>
					</div>

					<v-text-field type="password" v-model="password" label="密码" required :rules="[rules.length(6)]"></v-text-field>
					
					<v-text-field type="password" v-model="passwordConfirm" label="确认密码" required :rules="[rules.length(6),passwordCheck]"></v-text-field>
					
					<div class="d-flex justify-space-between">
						<v-btn text class="button text-none" to="/login">返回登录</v-btn>
					</div>
					
					<v-btn :disabled="!valid" :loading="btn_loading" @click="confirm" large color="primary" block class="mt-7 text-none">确认</v-btn>
					
				</v-form>
			</v-card-text>
		</v-card>

	</div>


</template>

<script>
	import http from '../api/http';

	export default {
		data: () => ({
			valid: true,
			show_sned_code: true,
			auth_time: 0,
			email: '',
			code: '',
			password: '',
			passwordConfirm: '',
			btn_loading: false,
			send_code_loading: false,
		}),
		computed: {
			rules() {
				let rules = {
					required: value => !!value || "请输入验证码",
					length: len => v => (v || '').length >= len || '不少于' + `${len}`,
					email: v => !!(v || '').match(/@/) || '邮箱格式不正确',
				}

				return rules
			},
		},
		methods: {
			toast(msg) {
				this.snackbar_is_show = true
				this.snackbar_msg = msg
			},
			passwordCheck(){
				var res = this.password == this.passwordConfirm ? true : "两次密码不一致"
				
				return res
			},
			async sendCode() {
                if (!this.email) {
                    this.$root.$emit("show-snackbar", {
                        text: "邮箱格式不正确",
                    })
                    return
                }

                this.send_code_loading = true
                const data = {
                    type: "register",
                    email: this.email,
                }

                try {
                    await http.post('/user/send-code', data)
                    this.send_code_loading = false

                    this.show_sned_code = false
                    this.auth_time = 60
                    var auth_timetimer =  setInterval(()=> {
                        this.auth_time--
                        if(this.auth_time<=0){
                            this.show_sned_code = true
                            clearInterval(auth_timetimer)
                        }
                    }, 1000)

                } catch (error) {
                    this.send_code_loading = false
					this.$root.$emit('show-snackbar', {
						text: error.response.data.msg
					})
                }

			},

			async confirm() {
				const checkForm = this.$refs.form.validate()
                if (checkForm == false) {
                    return
                }

                const data = {
                    "type": 2,
                    "email": this.email,
                    "code": this.code,
                    "password": this.passwordConfirm,
                    "invite": this.getArgs('invite')
                }

                try {

                    this.btn_loading = true
                    const response = await http.post('/user/register', data)
                    this.btn_loading = false

                    localStorage.setItem('token', response.data.data.token)
                    localStorage.setItem('email', this.email)
                    this.$router.push( { path: '/overview' } )

                } catch (error) {
                    this.btn_loading = false
					this.$root.$emit('show-snackbar', {
						text: error.response.data.msg
					})
                }


			},
			getArgs(name) {
				var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i")
				var r = window.location.search.substr(1).match(reg)
				if (r != null) return unescape(r[2])
				return null
			}
		},
	}
</script>

<style scoped>
	.card{
		max-width: 450px;
		width: 90%;
	}
	.button{
		padding: 0 !important;
	}
	.code{
		position: absolute;
		right: 15px;
	}
</style>
